import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../../themes/main-colors';
import RichText from '../rich-text';

interface PGEAccordionProps {
  accordionItems: any;
  accordionHeader?: string;
  accordionIntroCopy?: any;
  colorBackground?: string;
}

const PGEAccordion: React.FC<PGEAccordionProps> = ({
  accordionItems,
  accordionIntroCopy,
  accordionHeader,
  colorBackground
}) => {
  const initialItems: any = accordionItems?.length >  0
    ? [...accordionItems]
    : [];

  const [expands, setExpands] = React.useState<Array<boolean>>([
    ...initialItems?.fill(false),
  ]);

  // Handle Expand and Collapse of Accordion
  const handleChange = (index: number) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ): void => {
    const data: Array<boolean> = [...expands];
    data[index] = isExpanded;

    setExpands(data);
  };

  const theme = useTheme()

  return (
    <div css={{
      width: '100%',
      padding: theme.spacing(5, 7.5),
      boxShadow: '0px 3px 6px #00002930',
      borderRadius: theme.spacing(0.675),
      backgroundColor: colorBackground
        ? colorBackground
        : 'transparent',

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2.5),
      },

      '& > div.MuiPaper-root': {
        margin: '16px 0',
        boxShadow: '0px 3px 6px #00002930',
        borderRadius: theme.typography.pxToRem(5),
        '& > div > div:nth-child(1)': {
          display: 'block',
        },
        '& > div.icon > div:nth-child(1)': {
          display: 'flex',
          '& > div > div > img': {
            marginRight: theme.typography.pxToRem(10),
            width: theme.typography.pxToRem(33.32),
            height: theme.typography.pxToRem(28),
          },
        },
      },

      '& > div:before': {
        height: '0',
      },

      '& .MuiAccordionSummary-root': {
        minHeight: theme.spacing(8.75),
      },
    }}>
      {accordionHeader && (
        <Typography variant="h4" css={{
          fontSize: theme.spacing(4.5),
          lineHeight: theme.typography.pxToRem(40),
          fontWeight: theme.spacing(12.5),
          color: colors.noirBlur,
          [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(3),
          },
        }}>
          {accordionHeader}
        </Typography>
      )}
      {accordionIntroCopy && (
        <RichText className={'RichText'}>
          {accordionIntroCopy}
        </RichText>
      )}
      {accordionItems?.map((panel: any, index: number) => (
        <Accordion
          data-testid={index}
          expanded={expands[index]}
          onChange={handleChange(index)}
          key={index}
        >
          <AccordionSummary
            data-testid={`panel${index}bh-header`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            className={panel.tabTitleIcon ? 'icon' : ''}
          >
            <Grid container>
              {panel.tabTitleIcon && (
                <Grid item xs={1} css={{
                  flexGrow: 0,
                  maxWidth: '5.333333%',
                  flexBasis: '5.333333%',
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '12.333333%',
                    flexBasis: '12.333333%',
                  },
                }}>
                  {panel?.tabTitleIcon?.file && (
                    <img
                      src={panel?.tabTitleIcon?.file?.url}
                      alt={panel?.tabTitle}
                      width="30"
                      height="30"
                    />
                  )}
                  {panel?.tabTitleIcon?.$$typeof && <>{panel?.tabTitleIcon}</>}
                </Grid>
              )}
              {(panel?.tabTeaser || panel.tabTitle) && (
                <Grid
                  item
                  xs={panel?.tabTitleIcon ? 11 : 12}
                  css={{
                    ...(panel?.tabTitleIcon ? {
                      flexGrow: 0,
                      maxWidth: '94.666667%',
                      flexBasis: '94.666667%',
                      [theme.breakpoints.down('md')]: {
                        maxWidth: '87.666667%',
                        flexBasis: '87.666667%',
                      },
                    } : {})
                  }}
                >
                  <Typography css={{
                    fontSize: theme.typography.pxToRem(24),
                    flexShrink: 0,
                    lineHeight: theme.typography.pxToRem(28),
                    [theme.breakpoints.down('md')]: {
                      fontSize: theme.typography.pxToRem(20),
                      lineHeight: theme.typography.pxToRem(24),
                    },
                  }}>
                    {panel.tabTitle}
                  </Typography>

                  {panel?.tabTeaser && (
                    <Typography css={{
                      fontSize: theme.typography.pxToRem(16),
                      color: colors.noirBlur,
                      lineHeight: theme.typography.pxToRem(20),
                      marginTop: theme.spacing(1.25),
                    }}>
                      {panel?.tabTeaser}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails css={{
            '& img': {
              width: 'inherit',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            },
            '& .RichText': {
              width: '100%',
            },
            '& .RichText p a': {
              [theme.breakpoints.down('md')]: {
                wordBreak: 'break-all',
              },
            },
          }}>
            {panel?.tabBody?.raw ? (
              <RichText className={'RichText'}>
                {panel?.tabBody}
              </RichText>
            ) : (
              panel?.tabBody
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default PGEAccordion;
